<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :server-items-length="total"
    :options.sync="options"
    :loading="loading"
    class="elevation-1"
    item-key="id"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Usuarios Sin Aprobación</v-toolbar-title>

        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>

        <v-spacer></v-spacer>

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>
      </v-toolbar>

      <v-dialog
        v-model="approveDialog"
        max-width="100%"
        width="450"
        :style="{ zIndex: 200 }"
        @keydown.esc="approveDialog = false"
      >
        <v-card>
          <v-card-title style="word-break: break-word;">¿Aprobar el usuario?</v-card-title>
          <v-card-actions class="pt-3">
            <v-spacer></v-spacer>
            <v-btn
              color="grey"
              text
              class="body-2 font-weight-bold"
              @click="approveDialog = false"
              >Cancelar</v-btn
            >
            <v-btn
              color="primary"
              class="body-2 font-weight-bold"
              outlined
              @click="approveUser"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="deleteDialog"
        max-width="100%"
        width="450"
        :style="{ zIndex: 200 }"
        @keydown.esc="deleteDialog = false"
      >
        <v-card>
          <v-card-title style="word-break: break-word;">¿Eliminar el usuario?</v-card-title>
          <v-card-text>
            También se van a eliminar los turnos que tenga el paciente.
          </v-card-text>
          <v-card-actions class="pt-3">
            <v-spacer></v-spacer>
            <v-btn
              color="grey"
              text
              class="body-2 font-weight-bold"
              @click="deleteDialog = false"
              >Cancelar</v-btn
            >
            <v-btn
              color="primary"
              class="body-2 font-weight-bold"
              outlined
              @click="deleteUser"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template v-slot:item.action="{ item }">
      <v-icon
        color="primary"
        class="mr-2"
        title="Aprobar Usuario"
        @click="approveItem(item)"
      >
        mdi-check
      </v-icon>
      <v-icon
        color="error"
        title="Borrar Usuario"
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>

import _ from 'lodash'

export default {
  data: () => ({
    approveDialog: false,
    deleteDialog: false,
    loading: false,
    saving: false,
    search: '',
    headers: [
      { text: 'Nombre de Usuario', value: 'name' },
      { text: 'E-Mail', value: 'email' },
      { text: 'Paciente', value: 'paciente_descripcion' },
      { text: 'Acciones', value: 'action', align: 'right', sortable: false },
    ],
    items: [],
    total: 0,
    options: {},
    user: {},
  }),

  watch: {
    options: {
      handler () {
        this.query()
      },
      deep: true,
    },

    search: function (/*val*/) {
      this.debounceRefresh()
    },
  },

  methods: {
    debounceRefresh: _.debounce(function () {
      this.refresh()
    }, 1000),

    refresh: function () {
      this.options.page = 1
      this.query()
    },

    query: function () {
      this.loading = true;

      const filters = {
        aprobado: false,
        filter: this.search,
        sortBy: this.options.sortBy[0] || null,
        descending: this.options.sortDesc[0] || null,
        rowsPerPage: this.options.itemsPerPage,
        page: this.options.page,
      };

      this.$http.get('usuarios', {params: filters})
      .then((response) => {
        this.items = response.data
        this.total = parseInt(response.headers['pager-total'])
      })
      .catch((error) => {
        switch (error.response.status) {
          case 401:
            break;
          default:
            this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
      .then(() => {
        this.loading = false;
      })
    },

    approveItem: function (item) {
      this.user = item
      this.approveDialog = true
    },

    approveUser: function () {
      this.$http.put(`usuarios/${this.user.id}/aprobar`)
        .then(() => {
          this.query();
        })
        .catch((error) => {
          this.$eventHub.$emit(
            'snackbar-message',
            error?.response?.data?.message || 'Disculpe, no se pudo procesar su solicitud',
            'error')
        })
        .finally(() => {
          this.user = {};
          this.approveDialog = false
        })
    },

    deleteItem: function (item) {
      this.user = item
      this.deleteDialog = true
    },

    deleteUser: function () {
      this.$http.delete(`usuarios/${this.user.id}`, {
        params: {
          'force': true
        }
      })
        .then(() => {
          this.query();
        })
        .catch((error) => {
          this.$eventHub.$emit(
            'snackbar-message',
            error?.response?.data?.message || 'Disculpe, no se pudo procesar su solicitud',
            'error')
        })
        .finally(() => {
          this.user = {};
          this.deleteDialog = false
        })
    },
  },
};
</script>
